<script setup>
import logo from "@/assets/df-logo-DFFF4F.png";
import df from "@/assets/df-logo-square-DFFF4F.png";
import { useAccountStore } from "@/stores/account";
import router from "@/router";
const accountStore = useAccountStore();

import { ref, onMounted, onUnmounted } from "vue";

const term = ref("");
const drawer = ref(false);
const searchDialog = ref(false);

const onSearch = () => {
  if (term.value) {
    router.push("/search?term=" + term.value);
  }

  searchDialog.value = false;
  term.value = "";
};

const goToSearch = () => {
  drawer.value = false;
  router.push("/search");
};

import { useIndexStore } from "@/stores";

const checkForNewVersion = async () => {
  await fetchVersion();

  if (compareVersions(useIndexStore().release, latestVersion.value)) {
    console.log("is new version");
    useIndexStore().setRelease(latestVersion.value);
    // this.reload('An newer version of this website is available.')
    window.location.reload();
  }
};

function compareVersions(versionA, versionB) {
  // Split the version strings into arrays of numbers
  const partsA = versionA.toString().split(".").map(Number);
  const partsB = versionB.toString().split(".").map(Number);

  // Compare each part
  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const numA = partsA[i] || 0; // Use 0 if the version has fewer parts
    const numB = partsB[i] || 0;

    if (numA > numB) {
      return false;
    } else if (numA < numB) {
      return true;
    }
  }

  return false;
}

const latestVersion = ref("");

// Function to fetch the version from package.json
const fetchVersion = async () => {
  const fetchWithTimeout = (url, options, timeout = 5000) => {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error("Request timed out"));
      }, timeout);

      fetch(url, options)
        .then((response) => {
          clearTimeout(timer);
          resolve(response);
        })
        .catch((error) => {
          clearTimeout(timer);
          reject(error);
        });
    });
  };

  try {
    const response = await fetchWithTimeout(
      `/version.json?timestamp=${new Date().getTime()}`,
      {},
      5000 // set your timeout in milliseconds
    );

    const data = await response.json();
    console.log("version json: " + data);
    latestVersion.value = data.version; // Update the current version
  } catch (error) {
    if (error.message === "Request timed out") {
      console.warn("Fetch version timed out");
    } else {
      console.error("Error fetching version:", error);
    }
  }
};

onMounted(() => {
  if (import.meta.env.PROD) {
    // Start the interval when the component is mounted
    const intervalId = setInterval(() => {
      checkForNewVersion(); // Call the function every 10 seconds
    }, 10000);

    // Clear the interval when the component is unmounted to avoid memory leaks
    onUnmounted(() => {
      clearInterval(intervalId);
    });
  }
});
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" location="right" :temporary="true">
      <v-list aria-label="Menu items">
        <v-list-item
          :to="{ name: 'future-of', params: { slug: 'business' } }"
          @click="drawer = false"
          class="ml-2 future-of-business"
          role="option"
        >
          Future of Business
        </v-list-item>
        <v-list-item
          :to="{ name: 'future-of', params: { slug: 'finance' } }"
          @click="drawer = false"
          class="ml-2 future-of-finance"
          role="option"
        >
          Future of Finance
        </v-list-item>
        <v-list-item
          :to="{ name: 'future-of', params: { slug: 'culture' } }"
          @click="drawer = false"
          class="ml-2 future-of-culture"
          role="option"
        >
          Future of Culture
        </v-list-item>
      </v-list>

      <v-divider
        class="border-opacity-100 ml-6 mr-6 mt-4 mb-3"
        style="height: 40px"
        color="#bbb"
      ></v-divider>

      <v-list aria-label="Menu items">
        <v-list-item
          :to="{ name: 'podcast-index' }"
          @click="drawer = false"
          role="option"
        >
          Podcasts
        </v-list-item>
        <v-list-item
          href="https://digitalfrontier.newsstand.co.uk"
          target="_blank"
          @click="drawer = false"
          role="option"
        >
          Magazine
        </v-list-item>

        <v-list-item
          @click="goToSearch"
          role="option"
          aria-label="Go to search"
        >
          <v-icon
            variant="text"
            icon="fas fa-magnifying-glass"
            color="primary"
            title="Search"
          ></v-icon>
        </v-list-item>

        <div v-if="accountStore.authenticated">
          <v-list-item
            :to="{ name: 'account' }"
            @click="drawer = false"
            role="option"
          >
            Account
          </v-list-item>
          <v-list-item
            :to="{ name: 'subscriptions' }"
            v-if="!accountStore.user.subscribed"
            @click="drawer = false"
            role="option"
          >
            Subscribe
          </v-list-item>
          <v-list-item
            :to="{ name: 'logout' }"
            @click="drawer = false"
            role="option"
          >
            Sign out
          </v-list-item>
        </div>
        <div v-else>
          <v-list-item
            :to="{ name: 'login' }"
            @click="drawer = false"
            role="option"
          >
            Sign in
          </v-list-item>
          <v-list-item
            @click="drawer = false"
            role="option"
            aria-selected="false"
          >
            <v-btn
              v-if="!accountStore.user.subscribed"
              variant="flat"
              color="primary"
              @click="router.push({ name: 'subscriptions' })"
              style="margin-left: 9px"
              class="mt-4 mr-2"
              >Subscribe</v-btn
            >
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar scroll-behavior="hide" elevation="0">
      <v-app-bar-title>
        <router-link :to="{ name: 'home' }" title="Home">
          <v-img
            v-if="router.currentRoute.value.name === 'home'"
            :src="df"
            alt="Digital Frontier"
            width="60"
          />
          <v-img
            v-else
            :src="logo"
            alt="Digital Frontier"
            max-width="200"
            class="mr-10 mr-sm-0"
          />
        </router-link>
      </v-app-bar-title>

      <div
        class="d-none d-md-flex justify-space-around align-center hide-on-collapse ml-3"
      >
        <div class="top-menu d-md-flex justify-space-around align-center">
          <router-link
            :to="{ name: 'future-of', params: { slug: 'business' } }"
            class="future-of-business"
            >Future of Business</router-link
          >
          <router-link
            :to="{ name: 'future-of', params: { slug: 'finance' } }"
            class="future-of-finance"
            >Future of Finance</router-link
          >
          <router-link
            :to="{ name: 'future-of', params: { slug: 'culture' } }"
            class="future-of-culture"
            >Future of Culture</router-link
          >
          <v-divider
            vertical
            class="border-opacity-100 ml-2 mr-4"
            style="height: 40px"
            color="#bbb"
          ></v-divider>

          <router-link :to="{ name: 'podcast-index' }">Podcasts</router-link>
          <a href="https://digitalfrontier.newsstand.co.uk" target="_blank"
            >Magazine</a
          >
          <!--            <a href="https://www.convergenceevent.com/" target="_blank">Events</a>-->
          <!--            <router-link :to="{ name: 'contact'}">Contact</router-link>-->
        </div>

        <v-spacer></v-spacer>
      </div>

      <v-spacer class="d-none d-sm-block"></v-spacer>

      <v-btn
        @click="goToSearch"
        variant="text"
        icon="fas fa-magnifying-glass"
        color="primary"
        title="Search"
        class="d-none d-sm-block"
        aria-label="Go to search page"
      ></v-btn>

      <!--        <v-dialog transition="dialog-top-transition" width="300" v-model="searchDialog">-->

      <!--          <v-text-field-->
      <!--              placeholder="search"-->
      <!--              v-model="term"-->
      <!--              density="compact"-->
      <!--              variant="solo"-->
      <!--              label="Search templates"-->
      <!--              append-inner-icon="fas fa-magnifying-glass"-->
      <!--              single-line-->
      <!--              hide-details-->
      <!--              @click:append-inner="onSearch"-->
      <!--              @keydown="$event.keyCode === 13 ? onSearch() : false"-->
      <!--              color="#34498e"-->
      <!--          ></v-text-field>-->
      <!--        </v-dialog>-->
      <v-btn
        v-if="!accountStore.user.subscribed"
        variant="flat"
        color="primary"
        @click="router.push({ name: 'subscriptions' })"
        class="d-none d-sm-block ml-2 mr-2"
        >Subscribe</v-btn
      >
      <div
        v-if="accountStore.authenticated"
        class="d-none d-md-flex mr-0 mr-md-0 hide-on-collapse"
      >
        <!--          <p>Welcome<br/>{{ accountStore.user.name }}</p>-->

        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn
              color="primary"
              v-bind="props"
              icon="fas fa-user"
              class="mr-3"
              aria-label="Open user menu"
            >
            </v-btn>
          </template>

          <v-list aria-label="Signed in user menu items">
            <v-list-item to="/account" role="option">
              <v-list-item-title>Account</v-list-item-title>
            </v-list-item>
            <v-list-item to="/logout" role="option">
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else class="mr-6 d-none d-md-flex hide-on-collapse">
        <v-btn class="ml-3 mr-3" @click="router.push({ name: 'login' })"
          >Sign in</v-btn
        >
        <!--          <v-btn variant="flat" color="primary" @click="router.push({name: 'subscriptions'})">Subscribe</v-btn>-->
      </div>
      <v-btn
        v-if="!accountStore.user.subscribed"
        class="d-block d-sm-none"
        variant="flat"
        size="small"
        color="primary"
        @click="router.push({ name: 'subscriptions' })"
        >Subscribe</v-btn
      >

      <v-app-bar-nav-icon
        color="primary"
        variant="text"
        @click.stop="drawer = !drawer"
        class="d-md-none"
        aria-label="Open menu"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <!--      <v-sheet class="mt-16 d-flex justify-center">-->
    <!--        <p class="text-center mt-10" style="width: 30%"><router-link :to="{ name: 'home'}"><v-img :src="logo" /></router-link></p>-->
    <!--      </v-sheet>-->

    <router-view v-slot="{ Component }">
      <template v-if="Component">
        <transition name="fade">
          <Suspense>
            <template #default>
              <div>
                <component :is="Component" />
              </div>
            </template>

            <template #fallback>
              <div class="mt-16 fill-height d-flex justify-center align-center">
                <v-progress-circular
                  color="black"
                  indeterminate
                  :size="100"
                  :width="15"
                ></v-progress-circular>
              </div>
            </template>
          </Suspense>
        </transition>
      </template>
    </router-view>
  </v-app>
</template>
